import { useEffect } from "react"
import tw from "twin.macro"
import { useRouter } from "next/router"
import Link from "next/link"

import { H2, IntroBody } from "styles/Text"

import API from "lib/api"

import Section from "components/Section"
import PrimaryButton from "components/Buttons/Primary"
import TileGrid, { TileTypes } from "components/TileGrid"

const Custom404 = ({ pageData }) => {
  const router = useRouter()
  useEffect(() => {
    if (router.asPath === "/") {
      router.push("/coming-soon")
    }
  }, [router])
  return (
    <div tw="py-52">
      <Section gridSize={{ lg: 2 / 3 }}>
        <H2 tw="text-center" as="h1">
          We can’t find the page you are looking for.
        </H2>
        <IntroBody tw="text-dark-grey mt-5  text-center">
          404 error - we can’t find the page you are looking for, but we did
          find these experiences for you on Bunuba country.
        </IntroBody>
      </Section>

      <Section gridSize={{ lg: 1 }}>
        {pageData?.length > 0 && (
          <TileGrid tiles={pageData} type={TileTypes.EXPERIENCE} />
        )}
        <div tw="text-center">
          <Link href="/" passHref>
            <PrimaryButton tw="mt-7.5">Back to home</PrimaryButton>
          </Link>
        </div>
      </Section>
    </div>
  )
}

export async function getStaticProps() {
  try {
    const [{ data: theme }, { data: nav }] = await Promise.all([
      API.cachedGet("/theme", 60000),
      API.cachedGet("/navigation", 60000),
    ])
    const { data } = await API.gql(
      `{
        experiencePages
        (
          limit: 5
        )
        {
          Name
          UID
          id
          PhoneNumber
          Email
          Website
          Address
          Cost
          Location {
            id
            Name
          }
          Hero {
            BackgroundImage {
              url
              alternativeText
            }
          } 
        }
      }`
    )

    const pageData = data?.data?.experiencePages || []

    return {
      revalidate: 5,
      props: {
        pageData,
        theme,
        nav,
        notFound: true,
      },
    }
  } catch (e) {
    return {
      revalidate: 5,
      props: {},
    }
  }
}
export default Custom404
